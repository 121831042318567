import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Box, Flex, HStack, IconButton, Image, Spacer, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { formatUnit, isReplenishmentRequired } from '../utils';
import type { PartCard_PartFragment } from './PartCard.generated';

gql`
  fragment PartCard_Part on Part {
    id
    name
    description
    area
    unit
    stock
    orderPoint
    cost
    manufacturer
    model
    thumbnailUrl
    office {
      id
      name
    }
  }
`;

export type PartCardProps = PartCard_PartFragment & {
  onPartClicked: (partId: number) => void;
  onPartDetailClicked?: (partId: number) => void;
  isActive?: boolean;
  showOfficeName?: boolean;
};

const PartCard: FC<PartCardProps> = (props: PartCardProps) => {
  const {
    id: partId,
    name,
    unit,
    stock,
    orderPoint,
    area,
    onPartClicked,
    onPartDetailClicked,
    isActive = false,
    thumbnailUrl,
    model,
    office,
    showOfficeName = false,
  } = props;

  const { t, i18n } = useTranslation();
  const isReplenishmentWarning = isReplenishmentRequired(stock, orderPoint);

  const onPartInfoClicked = (e: React.MouseEvent) => {
    if (!onPartDetailClicked) throw new Error('onPartDetailClicked is not defined');
    e.stopPropagation();
    onPartDetailClicked(partId);
  };

  return (
    <Box
      p={2}
      width='auto'
      borderWidth={1}
      borderLeft='unset'
      borderRight='unset'
      bg={isActive ? 'primary.50' : 'transparent'}
      _hover={{
        bg: isActive ? 'primary.50' : 'neutral.50',
        cursor: 'pointer',
      }}
      onClick={() => onPartClicked(partId)}
    >
      <HStack>
        <Image
          src={thumbnailUrl || '/defaults/part-thumbnail.svg'}
          alt='part thumbnail'
          objectFit='cover'
          rounded='full'
          boxSize='50px'
          minW='50px'
          minH='50px'
          bgColor='primary.50'
          border='1px solid'
          borderColor={thumbnailUrl ? 'transparent' : 'primary.500'}
          ml={1}
        />

        <Stack width='100%' spacing={0}>
          <Flex alignItems='center'>
            <Text fontSize='md' letterSpacing='wide' color='neutral.800' mr={2}>
              {name}
            </Text>
            <Spacer />
            <Text
              fontSize='sm'
              minW='max-content'
              letterSpacing='wide'
              color={isReplenishmentWarning ? 'error.500' : 'neutral.800'}
            >
              {isReplenishmentWarning && t('replenish')}
              {i18n.language === 'en' ? ` ${stock} ` : stock}
              {formatUnit(unit) === '個' ? t('pieces') : formatUnit(unit)}
            </Text>
          </Flex>
          <Box pt={2}>
            {model && (
              <Text fontSize='sm' letterSpacing={'wide'} color='neutral.500'>
                {model}
              </Text>
            )}
            {showOfficeName && (
              <Text fontSize='sm' letterSpacing='wide' color='neutral.500'>
                {office.name}
              </Text>
            )}
            <Text fontSize='sm' letterSpacing='wide' color='neutral.500'>
              {area}
            </Text>
            <Spacer />
          </Box>
        </Stack>

        {onPartDetailClicked && (
          <IconButton
            aria-label='Show Part Details'
            size='sm'
            variant='ghost'
            onClick={onPartInfoClicked}
            icon={<MdInfoOutline />}
          />
        )}
      </HStack>
    </Box>
  );
};

export default PartCard;
